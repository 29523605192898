<template>
    <div style="width: 100%;" class="home">
        <header>
            <homeHeader />
        </header>
        <div class="content">
            <div class="title">
                <div>
                    <img src="../static/img/icon_kclb.png">
                    <span>我的收藏</span>
                </div>
                <span>共创建{{ collect.length || 0 }}个收藏夹</span>
            </div>
            <div class="list">
                <div class="list_item" v-for="item, index in collect" :key="index" @click="toDetail(item.id)">
                    <img :src="'https://eeapi.appcloud.tech' + item.subjectList[0].imageUrl" class="photo"
                        v-if="item.subjectList.length > 0">
                    <div class="photo" v-else>
                        <img src="../static/img/icon_k.png">
                    </div>
                    <div>
                        <div class=" name">{{ item.folderName }}
                        </div>
                        <div class="text">
                            <div class="left">共有{{ item.subjectList.length }}门课程</div>
                            <div class="right">
                                <img src="../static/img/icon_delete.png" @click.stop="id = item.id; qxModal = true">
                            </div>
                        </div>
                    </div>
                </div>
                <div style="clear:both;"></div>
            </div>
            <el-dialog title="" :visible.sync="qxModal" width="20%">
                <h2 style="width: 100%;text-align: center;">是否删除收藏夹?</h2>
                <div class="btn2">
                    <div class="qx" @click="qxModal = false">取消</div>
                    <el-button class="qd" type="primary" @click="deleteFolder()">
                        确定
                    </el-button>
                </div>
            </el-dialog>
        </div>
        <homeFooter />
    </div>
</template>

<script>
import homeHeader from '@/components/homeHeader.vue'
import homeFooter from '@/components/homeFooter.vue'
import { queryCollectSubjectList, deleteFolder } from "@/api/home";
export default {
    components: {
        homeHeader, homeFooter
    },
    data() {
        return {
            id: '',
            collect: [],
            qxModal: false
        }
    },
    mounted() {
        this.queryCollectSubjectList()
    },
    beforeDestroy() {
    },
    methods: {
        toDetail(id) {
            this.$router.push({
                path: '/myCollectList',
                query: { id }
            })
        },
        async deleteFolder() {
            try {
                let res = await deleteFolder({ id: this.id });
                if (res.code == 200) {
                    this.$message.success('删除成功');
                    this.queryCollectSubjectList()
                }
            } finally {
                this.qxModal = false;
            }
        },
        async queryCollectSubjectList() {
            let res = await queryCollectSubjectList({});
            if (res.code == 200) {
                this.collect = res.data;
            }
        },
    }
}
</script>
<style scoped lang="less">
header {
    width: 100%;
    height: .92rem;
}

.home {
    background: #f5f6f8;
    width: 100%;
}

.btn2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: .3rem;

    .qx {
        width: 1.43rem;
        height: .48rem;
        border: .01rem solid #DDDDDD;
        border-radius: .24rem;
        font-size: .18rem;
        font-family: 'PingFang SC';
        font-weight: 400;
        color: #36393D;
        text-align: center;
        line-height: .48rem;
        margin: 0 .15rem;
        cursor: pointer;
        transition: filter 0.3s;

        &:hover {
            filter: brightness(1.01);
        }
    }

    .qd {
        width: 1.43rem;
        height: .48rem;
        background: linear-gradient(90deg, #4C88EB, #3D70E7);
        border-radius: .24rem;
        font-size: .18rem;
        font-family: 'PingFang SC';
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        margin: 0 .15rem;
        cursor: pointer;
        transition: filter 0.3s;

        &:hover {
            filter: brightness(1.1);
        }
    }
}

.content {
    width: 14.78rem;
    margin: auto;
    padding-bottom: .5rem;

    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: .39rem 0;
        font-size: .18rem;
        font-family: 'PingFang SC';
        font-weight: 400;
        color: #979EA7;

        >div {
            display: flex;
            align-items: center;

            img {
                width: .4rem;
                height: .4rem;
                margin-right: .07rem;
            }

            >span {
                font-size: .3rem;
                font-family: 'pangmen';
                font-weight: 400;
                color: #2D2D2D;
            }
        }
    }

    .list {

        .list_item {
            width: 3.47rem;
            background: #FFFFFF;
            box-shadow: 0 0 .2rem 0 rgba(0, 0, 0, 0.3);
            border-radius: .1rem;
            display: inline-block;
            float: left;
            margin-right: .3rem;
            margin-bottom: .3rem;
            overflow: hidden;
            position: relative;
            cursor: pointer;
            transition: transform .3s;

            &:hover {
                transform: scale(1.03);
            }

            &:nth-child(4n) {
                margin-right: 0;
            }

            .photo {
                width: 100%;
                height: 1.96rem;
                background: #F1F1F1;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: .46rem;
                    height: .46rem;
                }
            }

            >div {
                padding: .19rem .2rem;
                box-sizing: border-box;
                width: 100%;

                .name {
                    font-size: .2rem;
                    font-family: 'PingFang SC';
                    font-weight: 700;
                    color: #36393D;
                }

                .text {
                    font-size: .16rem;
                    font-family: 'PingFang SC';
                    font-weight: 400;
                    color: #6D737A;
                    margin-top: .1rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    img {
                        width: .2rem;
                        height: .2rem;
                    }
                }
            }
        }
    }
}
</style>

