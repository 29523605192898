<template>
    <div style="width: 100%;" class="workDetail">
        <header>
            <homeHeader :active="2" />
        </header>
        <div class="content">
            <div class="back" @click="back()">
                <img src="../static/img/icon_back2.png">返回
            </div>
            <div class="section">
                <div class="section_title">
                    <div class="section1">
                        <div class="section1_">
                            总分:{{ allScore }}
                        </div>
                    </div>
                    <div class="section2">
                        {{ list.name ? list.name : '' }}
                    </div>
                    <div class="section3">
                        <img src="../static/img/icon_tm.png"> 共{{ questionList ? questionList.length : 0 }}道题
                        <img src="../static/img/icon_time2.png" style="margin-left: .56rem;">{{ duration ? duration : 0 }}
                        分钟
                    </div>
                </div>
            </div>
            <div class="bottom">
                <div class="title">
                    (本次考试仅有一次作答机会)
                </div>
                <div class="beginExam" @click="beginExam()" v-if="type == 'test'">进入测试</div>
                <div class="beginExam" @click="beginExam()" v-else>开始考试</div>
            </div>
        </div>
        <homeFooter style="margin-top: .8rem;" />
    </div>
</template>

<script>
import homeHeader from '@/components/homeHeader.vue'
import homeFooter from '@/components/homeFooter.vue'
import { startTesting, startExam } from "@/api/home";
export default {
    components: {
        homeHeader, homeFooter
    },
    data() {
        return {
            list: [],
            questionList: [],
            id: '',
            type: '',
            duration: '',
            allScore: 0
        }
    },
    mounted() {
        this.id = this.$route.query.id
        this.type = this.$route.query.type;
        if (this.type == 'test') {
            this.startTesting(this.id)
        } else {
            this.startExam(this.id)
        }
    },
    beforeDestroy() {
    },
    methods: {
        back() {
            window.history.back(-1);
            setTimeout(() => {
                if (this.$route.path == '/examDeatil') {
                    this.back()
                }
            }, 100);
        },
        beginExam() {
            this.$router.push({
                path: '/beginExam',
                query: {
                    id: this.id,
                    type: this.type,
                    duration: this.duration
                }
            })
        },
        async startTesting(id) {
            let res = await startTesting({
                id
            })
            try {
                if (res.code == 200) {
                    let questionList = [];
                    res.data.partList.forEach((d) => {
                        d.questionList.forEach((e) => {
                            questionList.push(e)
                        })
                        this.allScore += d.score
                    })
                    this.list = res.data;
                    this.questionList = questionList;
                    this.duration = res.data.duration
                }
            } catch (error) {
                this.back()
            }
        },
        async startExam(id) {
            try {
                let res = await startExam({
                    id
                })

                if (res.code == 200) {
                    let questionList = [];
                    res.data.partList.forEach((d) => {
                        d.questionList.forEach((e) => {
                            questionList.push(e)
                        })
                    })
                    this.list = res.data;
                    this.questionList = questionList;
                    this.duration = res.data.duration
                }
            } catch (error) {
                this.back()
            }
        }

    }
}
</script>
<style scoped lang="less">
header {
    width: 100%;
    height: .92rem;
}

.workDetail {
    background: #f5f6f8;
    width: 100%;
    padding-top: .3rem;
    box-sizing: border-box;
    overflow: hidden;
}

.content {
    width: 14.8rem;
    height: 6.68rem;
    margin: auto;
    padding-bottom: .5rem;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 0px .2rem 0px rgba(0, 0, 0, 0.16);
    border-radius: .16rem;
    overflow: hidden;
    position: relative;

    .back {
        font-size: .18rem;
        font-family: 'PingFang SC';
        font-weight: 400;
        color: #979EA7;
        display: flex;
        align-items: center;
        margin: .21rem 0 0 .48rem;
        cursor: pointer;

        img {
            width: .2rem;
            height: .2rem;
            margin-right: .08rem;
        }
    }

    .section {
        padding-top: .4rem;

        .section_title {
            width: 100%;

            .section1,
            .section2,
            .section3,
            .section4 {
                width: 100%;
                text-align: center;
                font-weight: bold;
                color: #5990ec;
            }

            .section1 {
                width: 100%;
                display: flex;
                justify-content: center;
                white-space: nowrap;

                .section1_ {
                    width: 1.62rem;
                    height: 1.62rem;
                    background: rgba(76, 136, 235, 0.1);
                    border-radius: 50%;
                    font-size: .3rem;
                    font-family: 'PingFang SC';
                    font-weight: 500;
                    color: #4C88EB;
                    text-align: center;
                    line-height: 1.62rem;
                }
            }

            .section4 {
                width: 100%;
                display: flex;
                justify-content: center;
                margin-top: 30px;

                .section4_ {
                    font-size: 27px;
                    padding: 35px 130px;
                    background-color: #ffd684;
                    border-radius: 55px;
                    text-align: center;
                    font-weight: normal;
                    color: #000;
                    font-weight: bold;
                }


            }

            .section2 {
                font-size: .2rem;
                font-family: 'PingFang SC';
                font-weight: 500;
                color: #36393D;
                margin-top: .5rem;
            }

            .section3 {
                margin-top: .2rem;
                font-size: .16rem;
                font-family: 'PingFang SC';
                font-weight: 400;
                color: #6D737A;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: .2rem;
                    height: .2rem;
                    margin-right: .08rem;
                }
            }
        }
    }

    .bottom {
        background-color: #fff;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: .5rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        .title {
            width: 100%;
            text-align: center;
            font-size: .18rem;
            font-family: 'PingFang SC';
            font-weight: 400;
            color: #6D737A;
        }

        .beginExam {
            width: 4.4rem;
            height: .72rem;
            line-height: .72rem;
            background: linear-gradient(90deg, #4C88EB, #3D70E7);
            border-radius: .36rem;
            font-size: .2rem;
            text-align: center;
            color: #FFFFFF;
            margin-top: .31rem;
            cursor: pointer;
            transition: filter 0.3s;

            &:hover {
                filter: brightness(1.1);
            }
        }
    }

}
</style>

