<template>
    <div style="width: 100%;" class="lessonDetail">
        <header>
            <homeHeader :active="2" />
        </header>
        <div class="banner">
            <div class="content">
                <div class="title">{{ subjectInfo.name }}</div>
                <div class="introduce" :title="subjectInfo.introduce">
                    {{ subjectInfo.introduce }}
                </div>
                <div class="teacher">
                    <img src="../static/img/icon_ls (1).png">
                    {{ subjectInfo.teacherNames }}老师
                </div>
                <div class="bottom">
                    <div>
                        <span>
                            <img src="../static/img/icon_zj.png">已更新{{ subjectInfo.chapterNum }}章节
                        </span>
                        <span v-if="subjectInfo.type == 2">
                            <img src="../static/img/icon_rs (2).png">{{ subjectInfo.studyNum }}人参加
                        </span>
                    </div>
                    <div class="sc" v-if="subjectInfo.type == 2">
                        <img src="../static/img/icon_sc.png" v-if="subjectInfo.isCollect == 1" @click="qxModal = true">
                        <img src="../static/img/icon_wsc.png" v-else @click="scDialogVisible = true">
                    </div>
                    <el-dialog title="" :visible.sync="scDialogVisible" :show-close="false" :close-on-click-modal="false">
                        <div class="scModal">
                            <div class="top">
                                <span>选择收藏夹</span>
                                <div class="btn1" @click="newCollect()" v-if="!isCreateNew">+新建收藏夹</div>
                                <div class="btn1" @click="newCollect()" v-else>取消</div>
                            </div>
                            <div class="radioGroup">
                                <div v-for="item in collectSubjectList" :key="item.id" @click="select(item.id)"
                                    :class="item.select ? 'radio_item active' : 'radio_item'">
                                    <div>
                                        <img src="../static/img/xz.png" v-show="item.select && !isCreateNew">
                                        <img src="../static/img/wxz.png" v-show="!item.select && !isCreateNew">
                                        <span>{{ item.folderName }}</span>
                                    </div>
                                </div>
                                <div class="radio_item" v-if="isCreateNew">
                                    <input v-model="folderName" placeholder="请输入新增收藏夹名称" />
                                    <div class="btn4">
                                        <el-button class="qd" type="primary" @click="createNewCollect()">
                                            保存
                                        </el-button>
                                    </div>
                                </div>
                            </div>
                            <div class="btn2" v-show="!isCreateNew">
                                <div class="qx" @click="closeSc()">取消</div>
                                <el-button class="qd" type="primary" @click="collect()" :loading="loading">
                                    确定
                                </el-button>
                            </div>
                        </div>
                    </el-dialog>
                </div>
            </div>
        </div>
        <div class="nav">
            <div class="content">
                <div :class="active == 1 ? 'nav_item active' : 'nav_item'" @click="active = 1">课件</div>
                <div :class="active == 2 ? 'nav_item active' : 'nav_item'" @click="active = 2">测试与作业</div>
            </div>
        </div>
        <section>
            <div class="content">
                <div class="list" v-if="active == 1">
                    <div class="list_item1" v-for="item in subjectInfo.catalogueList" :key="item.id">
                        <template v-if="item.knowledgeList.length > 0 && item.knowledgeList[0].type == 1">
                            <div class="firstTitle">
                                {{ item.name }}
                            </div>
                            <div class="secondTitle" v-for="item2 in item.knowledgeList" :key="item2.id">
                                <div class="l">{{ item2.name }}</div>
                                <div class="r">
                                    <div v-if="subjectInfo.type != 2 || subjectInfo.isJoin">
                                        <div v-for="item3 in item2.contentList" :key="item3.id">
                                            <div class="pdf" v-if="item3.type == 1" @click="toInfo(item3)">
                                                <div class="img"></div>
                                                <span>PDF</span>
                                            </div>
                                            <div class="sp" v-if="item3.type == 2" @click="toInfo(item3)">
                                                <div class="img"></div>
                                                <span>视频</span>
                                            </div>
                                            <div class="wb" v-if="item3.type == 3" @click="toInfo(item3)">
                                                <div class="img"></div>
                                                <span>文本</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="list" v-if="active == 2">
                    <div class="list_item2" v-for="item in subjectInfo.catalogueList" :key="item.id">
                        <div class="firstTitle">
                            {{ item.name }}
                        </div>
                        <el-collapse>
                            <div class="secondTitle" v-for="item2 in item.knowledgeList" :key="item2.id">
                                <el-collapse-item :title="item2.name" :name="item2.id">
                                    <div class="text" v-if="item2.label">
                                        <div class="collapseleft">
                                            标签
                                        </div>
                                        <div class="collapseright">
                                            {{ item2.label }}
                                        </div>
                                    </div>
                                    <div class="text" v-if="item2.status == 2">
                                        <div class="collapseleft">
                                            分数
                                        </div>
                                        <div class="collapseright">
                                            {{ item2.score }} 分
                                        </div>
                                    </div>
                                    <div class="text" v-if="item2.status == 3">
                                        <div class="collapseleft">
                                            分数
                                        </div>
                                        <div class="collapseright">
                                            待评分
                                        </div>
                                    </div>
                                    <div class="text">
                                        <div class="collapseleft">
                                            学习次数
                                        </div>
                                        <div class="collapseright">
                                            {{ item2.makeNum }} 次
                                        </div>
                                    </div>
                                </el-collapse-item>
                                <div class="btns" v-if="item2.status == 2 && isAllowOpt == 1" @click="reStart(item2.id)">
                                    重新练习</div>
                                <div v-if='item2.type == 2'>
                                    <div class="btns" v-if="(item2.status == 0 || item2.status == 1) && isAllowOpt == 1"
                                        @click="toExam(item2.id)">
                                        前往测验
                                    </div>
                                    <div class="btns" v-if="item2.status == 2 && isAllowOpt == 1"
                                        @click="dialogVisible = true; examId = item2.id">申请重测</div>
                                </div>
                            </div>
                        </el-collapse>
                    </div>
                </div>
                <div class="right">
                    <div class="btn" @click="toLearn()" v-if="subjectInfo.type == 1 || subjectInfo.isJoin">开始学习</div>
                    <div class="btn" @click="join()" v-if="subjectInfo.type == 2 && !subjectInfo.isJoin">立即参加</div>
                    <div class="title">课程介绍</div>
                    <div class="introduce">
                        {{ subjectInfo.introduce }}
                    </div>
                </div>
                <div style="clear: both;"></div>
            </div>
        </section>
        <el-dialog title="申请重测" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false"
            :before-close="handleClose">
            <el-input type="textarea" :rows="4" placeholder="请输入申请原因" v-model="applyReason">
            </el-input>
            <div class="btn2">
                <div class="qx" @click="dialogVisible = false; applyReason = ''">取消</div>
                <el-button class="qd" type="primary" @click="applyConfilm()">
                    确定
                </el-button>
            </div>
        </el-dialog>
        <el-dialog title="" :visible.sync="qxModal" width="20%">
            <h2 style="width: 100%;text-align: center;">是否取消收藏</h2>
            <div class="btn2">
                <div class="qx" @click="qxModal = false">取消</div>
                <el-button class="qd" type="primary" @click="qxCollect()">
                    确定
                </el-button>
            </div>
        </el-dialog>
        <homeFooter />
    </div>
</template>

<script>
import homeHeader from '@/components/homeHeader.vue'
import homeFooter from '@/components/homeFooter.vue'
import { querySubjectInfo, applyUnitTesting, collectSubjectOpt, queryCollectSubjectList, createFolder, restartPractice, joinStudySubject } from "@/api/home";
export default {
    components: {
        homeHeader, homeFooter
    },
    data() {
        return {
            id: '',
            loading: false,
            subjectInfo: {},
            collectSubjectList: [],
            active: 1,
            isAllowOpt: false,
            dialogVisible: false,
            scDialogVisible: false,
            qxModal: false,
            examId: '',
            applyReason: '',
            radioValue: '',
            isCreateNew: false,
            folderName: '',
        }
    },
    mounted(e) {
        this.id = this.$route.query.id
        this.querySubjectInfo(this.id)
        this.queryCollectSubjectList()
    },
    beforeDestroy() {
    },
    methods: {
        async join() {
            let res = await joinStudySubject({
                id: this.id
            })
            if (res.code == 200) {
                this.$message.success('参加成功');
                this.querySubjectInfo(this.id)
            }
        },
        toLearn() {
            let check = false;
            try {
                this.subjectInfo.catalogueList.forEach((d) => {
                    if (d.knowledgeList && d.knowledgeList.length > 0) {
                        d.knowledgeList.forEach((c) => {
                            if (c.contentList && c.contentList.length > 0) {
                                check = true;
                                let item = c.contentList[0];
                                this.toInfo(item);
                                throw "stopIteration"
                            }
                        })
                    }
                })
            } catch (error) {
                if (e != "stopIteration") throw e;
            }
            if (!check) {
                this.$message('暂无课程')
            }
        },
        toExam(id) {
            this.$router.push({
                path: '/examDeatil',
                query: {
                    id,
                    type: 'test'
                }
            })
        },
        async reStart(id) {
            let res = await restartPractice({
                id
            })
            if (res.code == 200) {
                this.$router.push({
                    path: '/beginDailyWork',
                    query: {
                        id: res.data.id,
                        correction: 2
                    }
                })
            }
        },
        newCollect() {
            this.isCreateNew = !this.isCreateNew;
            this.folderName = '';
            this.resetRadio()
        },
        closeSc() {
            this.scDialogVisible = false;
            this.resetRadio()
        },
        resetRadio() {
            this.collectSubjectList.forEach((d) => {
                d.select = false;
            })
            this.radioValue = ''
        },
        select(id) {
            this.collectSubjectList.forEach((d) => {
                if (d.id == id) {
                    if (d.select) {
                        d.select = false;
                        this.radioValue = ''
                    } else {
                        d.select = true
                        this.radioValue = id
                    }
                } else {
                    d.select = false;
                }
            })
            this.$forceUpdate()
        },
        async createNewCollect() {
            let res = await createFolder({
                folderName: this.folderName
            })
            if (res.code == 200) {
                this.$message.success('新建成功')
                this.newCollect()
                this.queryCollectSubjectList()
            }
        },
        async queryCollectSubjectList() {
            let res = await queryCollectSubjectList({})
            if (res.code == 200) {
                this.collectSubjectList = res.data ? res.data : []
            }
        },
        async querySubjectInfo(id) {
            let res = await querySubjectInfo({ id })
            if (res.code == 200) {
                this.subjectInfo = res.data;
                this.isAllowOpt = res.data.isAllowOpt
            }
        },
        handleClose() {
            this.dialogVisible = false;
            this.applyReason = ''
        },
        async applyConfilm() {
            let res = await applyUnitTesting({
                id: this.examId,
                applyReason: this.applyReason
            })
            if (res.code == 200) {
                this.handleClose()
                this.$message.success('申请成功,请等待审核')
            }
        },
        async collect() {
            if (this.radioValue.length == 0) {
                this.$message.error('请先选择收藏夹')
            } else {
                try {
                    this.loading = true;
                    let res = await collectSubjectOpt({
                        status: 1,
                        studentFolderId: this.radioValue,
                        subjectId: this.id
                    })
                    this.loading = false;
                    this.$message.success('收藏成功')
                    this.querySubjectInfo(this.id);
                    this.scDialogVisible = false
                } catch (error) {
                    this.loading = false;
                }
            }
        },
        async qxCollect() {
            let res = await collectSubjectOpt({
                status: 2,
                subjectId: this.id
            })
            this.querySubjectInfo(this.id);
            this.qxModal = false
        },
        toInfo(item) {
            switch (item.type) {
                case 1:
                    this.$router.push({
                        path: '/lessonInfo',
                        query: {
                            id: this.id,
                            type: 1,
                            name: item.name,
                            url: item.url
                        }
                    })
                    break;
                case 2:
                    this.$router.push({
                        path: '/lessonInfo',
                        query: {
                            id: this.id,
                            type: 2,
                            name: item.name,
                            url: item.url
                        }
                    })
                    break;
                case 3:
                    sessionStorage.setItem('edu_content', item.content)
                    this.$router.push({
                        path: '/lessonInfo',
                        query: {
                            id: this.id,
                            type: 1,
                            name: item.name
                        }
                    })
                    break;
            }
        }
    }
}
</script>
<style scoped lang="less">
::v-deep .el-dialog {
    border-radius: .16rem;
}

header {
    width: 100%;
    height: .92rem;
}

.lessonDetail {
    background: #f5f6f8;
    width: 100%;
}

section {
    width: 100%;
    background: #F5F6F8;

    .content {
        padding-top: .3rem;
        padding-bottom: .5rem;

        .list {
            width: 10.28rem;
            background: #FFFFFF;
            box-shadow: 0px 0px .16rem 0px rgba(0, 0, 0, 0.2);
            border-radius: .16rem;
            padding: 0 .3rem .3rem .3rem;
            float: left;

            .list_item1 {
                .firstTitle {
                    width: 100%;
                    height: .89rem;
                    line-height: .89rem;
                    font-size: .21rem;
                    font-family: 'PingFang SC';
                    font-weight: 700;
                    color: #36393D;
                    border-bottom: .01rem solid #eee;
                }

                .secondTitle {
                    width: 100%;
                    height: .89rem;
                    line-height: .89rem;
                    font-size: .18rem;
                    font-family: 'PingFang SC';
                    font-weight: 500;
                    padding-left: .2rem;
                    color: #36393D;
                    border-bottom: .01rem solid #eee;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .r {
                        font-size: .18rem;
                        font-family: 'PingFang SC';
                        font-weight: 400;
                        color: #36393D;

                        >div {
                            display: flex;
                            align-items: center;
                        }

                        .pdf {
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            margin-left: .3rem;

                            .img {
                                width: .26rem;
                                height: .26rem;
                                margin-right: .05rem;
                                background-image: url(../static/img/icon_pdf.png);
                                background-size: 100% 100%;
                            }

                            &:hover {
                                .img {
                                    background-image: url(../static/img/icon_pdf_s.png);
                                }

                                color: #FF4720;
                            }
                        }

                        .sp {
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            margin-left: .3rem;

                            .img {
                                width: .26rem;
                                height: .26rem;
                                margin-right: .05rem;
                                background-image: url(../static/img/icon_sp.png);
                                background-size: 100% 100%;
                            }

                            &:hover {
                                .img {
                                    background-image: url(../static/img/icon_sp_s.png);
                                }

                                color: #FF7800;
                            }
                        }

                        .wb {
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            margin-left: .3rem;

                            .img {
                                width: .26rem;
                                height: .26rem;
                                margin-right: .05rem;
                                background-image: url(../static/img/icon_fwb.png);
                                background-size: 100% 100%;
                            }

                            &:hover {
                                .img {
                                    background-image: url(../static/img/icon_fwb_s.png);
                                }

                                color: #4C88EB;
                            }
                        }
                    }
                }
            }

            .list_item2 {
                .firstTitle {
                    width: 100%;
                    height: .89rem;
                    line-height: .89rem;
                    font-size: .21rem;
                    font-family: 'PingFang SC';
                    font-weight: 700;
                    color: #36393D;
                }

                .secondTitle {
                    width: 100%;
                    font-size: .18rem;
                    font-family: 'PingFang SC';
                    font-weight: 500;
                    color: #36393D;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    position: relative;

                    .btns {
                        position: absolute;
                        right: 0;
                        top: .26rem;
                        width: 1.43rem;
                        height: .48rem;
                        line-height: .48rem;
                        background: linear-gradient(90deg, #4C88EB, #3D70E7);
                        border-radius: .36rem;
                        font-size: .18rem;
                        text-align: center;
                        color: #FFFFFF;
                        margin-right: .1rem;
                        cursor: pointer;
                        transition: filter 0.3s;

                        &:hover {
                            filter: brightness(1.1);
                        }
                    }

                    ::v-deep .el-collapse-item__header {
                        display: block;
                        padding-left: .2rem;
                        width: 9.8rem;
                        height: 1rem;
                        line-height: 1rem;
                        background: #F7F8F9;
                        border-radius: .06rem;
                        font-weight: bold;
                        font-size: .18rem;

                        &:hover {
                            color: #4C88EB;
                        }
                    }

                    ::v-deep .el-collapse-item__arrow {
                        margin-left: .17rem;
                    }

                    ::v-deep .el-collapse-item__wrap {
                        border: 0;
                    }

                    .text {
                        display: flex;
                        align-items: center;
                        margin-top: .28rem;
                        font-size: .16rem;
                        font-family: 'PingFang SC';
                        font-weight: 400;
                        color: #36393D;
                        padding-left: .2rem;

                        .collapseleft {
                            width: 1.25rem;
                        }
                    }
                }
            }
        }

        .right {
            width: 4.12rem;
            background: #FFFFFF;
            box-shadow: 0 0 .16rem 0 rgba(0, 0, 0, 0.2);
            border-radius: .16rem;
            padding: .5rem .41rem;
            float: right;

            .btn {
                width: 100%;
                height: .62rem;
                background: #FF7800;
                border-radius: .31rem;
                font-size: .22rem;
                font-family: 'PingFang SC';
                font-weight: 500;
                color: #FFFFFF;
                text-align: center;
                line-height: .62rem;
                cursor: pointer;
                transition: filter 0.3s;

                &:hover {
                    filter: brightness(1.13);
                }

            }

            .title {
                font-size: .2rem;
                font-family: 'PingFang SC';
                font-weight: 700;
                color: #36393D;
                margin-top: .3rem;
            }

            .introduce {
                margin-top: .11rem;
                font-size: .16rem;
                font-family: 'PingFang SC';
                font-weight: 400;
                color: #6D737A;
                line-height: .3rem;
            }
        }
    }
}

.content {
    width: 14.78rem;
    margin: auto;
}

.btn2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: .3rem;

    .qx {
        width: 1.43rem;
        height: .48rem;
        border: .01rem solid #DDDDDD;
        border-radius: .24rem;
        font-size: .18rem;
        font-family: 'PingFang SC';
        font-weight: 400;
        color: #36393D;
        text-align: center;
        line-height: .48rem;
        margin: 0 .15rem;
        cursor: pointer;
        transition: filter 0.3s;

        &:hover {
            filter: brightness(1.01);
        }
    }

    .qd {
        width: 1.43rem;
        height: .48rem;
        background: linear-gradient(90deg, #4C88EB, #3D70E7);
        border-radius: .24rem;
        font-size: .18rem;
        font-family: 'PingFang SC';
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        margin: 0 .15rem;
        cursor: pointer;
        transition: filter 0.3s;

        &:hover {
            filter: brightness(1.1);
        }
    }
}

.banner {
    width: 100%;
    height: 3rem;
    background-image: url(../static/img/bg.png);
    background-size: 100% 100%;
    overflow: hidden;

    .title {
        font-size: .36rem;
        font-family: 'PingFang SC';
        font-weight: 600;
        color: #2D2D2D;
        margin: .42rem 0 .05rem;
    }

    .introduce {
        height: .73rem;
        font-size: .18rem;
        font-family: 'PingFang SC';
        font-weight: 400;
        color: #6D737A;
        line-height: .4rem;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }

    .teacher {
        display: flex;
        align-items: center;
        margin-top: .18rem;

        img {
            width: .2rem;
            height: .2rem;
            margin-right: .08rem;
        }

        font-size: 16px;
        font-family: 'PingFang SC';
        font-weight: 400;
        color: #6D737A;
    }

    .bottom {
        margin-top: .1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        >div {
            display: flex;
            align-items: center;
        }

        ::v-deep .el-dialog__header {
            display: none;
        }

        ::v-deep .el-dialog__body {
            padding: 0;
        }


        .scModal {
            width: 9.3rem;
            background: #FFFFFF;
            border-radius: .16rem;
            padding: .5rem;
            box-sizing: border-box;

            .top {
                display: flex;
                align-items: center;
                justify-content: space-between;

                span {

                    font-size: .36rem;
                    font-family: 'PingFang SC';
                    font-weight: 500;
                    color: #36393D;
                }

                .btn1 {
                    width: 1.6rem;
                    height: .48rem;
                    border: .01rem solid #4C88EB;
                    border-radius: .24rem;
                    font-size: .18rem;
                    font-family: 'PingFang SC';
                    font-weight: 400;
                    color: #4C88EB;
                    text-align: center;
                    line-height: .48rem;
                    cursor: pointer;
                    transition: filter 0.3s;

                    &:hover {
                        filter: brightness(1.1);
                    }
                }
            }

            .radioGroup {
                margin-top: .4rem;

                .radio_item {
                    width: 100%;
                    height: .76rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-left: .3rem;
                    border-bottom: .01rem solid #eee;
                    cursor: pointer;

                    >div {
                        display: flex;
                        align-items: center;
                    }

                    .qd {
                        width: 1.43rem;
                        height: .48rem;
                        background: linear-gradient(90deg, #4C88EB, #3D70E7);
                        border-radius: .24rem;
                        font-size: .18rem;
                        font-family: 'PingFang SC';
                        font-weight: 400;
                        color: #FFFFFF;
                        text-align: center;
                        margin: 0 .15rem;
                        cursor: pointer;
                        transition: filter 0.3s;

                        &:hover {
                            filter: brightness(1.1);
                        }
                    }

                    input {
                        width: 50%;
                        height: .76rem;
                        line-height: .76rem;
                        font-size: .18rem;
                        font-family: 'PingFang SC';
                        font-weight: 400;
                        border: 0;
                        outline: 0;

                        &::placeholder {
                            color: #BEC2C7;
                        }
                    }

                    img {
                        width: .24rem;
                        height: .24rem;
                        margin-right: .39rem;
                    }

                    span {
                        font-size: .18rem;
                        font-family: 'PingFang SC';
                        font-weight: 400;
                        color: #36393D;
                    }
                }

                .active {
                    background: #F7F8F9;
                }
            }
        }

        .sc {
            width: .64rem;
            height: .64rem;
            background: #4C88EB;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateY(-0.3rem);
            cursor: pointer;
        }

        span {
            display: flex;
            align-items: center;
            font-size: .16rem;
            font-family: 'PingFang SC';
            font-weight: 400;
            color: #4C88EB;
            margin-right: .2rem;

            img {
                width: .2rem;
                height: .2rem;
                margin-right: .08rem;
            }
        }
    }
}

.nav {
    width: 100%;
    height: 1rem;
    background: #FFFFFF;
    box-shadow: 0 .08rem .1rem 0 rgba(0, 0, 0, 0.04);

    .content {
        display: flex;
        align-items: center;
    }

    .nav_item {
        height: 1rem;
        font-size: .28rem;
        font-family: 'PingFang SC';
        font-weight: 400;
        color: #2D2D2D;
        margin-right: 1.08rem;
        line-height: 1rem;
        cursor: pointer;

        &:hover {
            color: #4C88EB;
        }
    }

    .nav_item.active {
        font-size: .36rem;
        font-family: 'AlimamaShuHeiTi';
        font-weight: bold;
        color: #4C88EB;
        position: relative;

        &::after {
            content: '';
            width: .22rem;
            height: .22rem;
            background: rgba(76, 136, 235, 0.3);
            border-radius: 50%;
            position: absolute;
            right: -0.02rem;
            bottom: .25rem;
        }
    }
}
</style>

