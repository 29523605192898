<template>
    <div style="width: 100%;" class="home">
        <header>
            <homeHeader />
        </header>
        <div class="content">
            <div class="title">
                <div>
                    <img src="../static/img/icon_kclb.png">
                    <span>{{ name }}</span>
                </div>
                <div style="cursor: pointer;" @click="qxModal = true"><img src="../static/img/icon_delete.png"
                        class="del">删除收藏夹</div>
            </div>
            <div class="list">
                <div class="list_item" v-for="item, index in lessonList" :key="index" @click="toDetail(item.subjectId)">
                    <img :src="'https://eeapi.appcloud.tech' + item.imageUrl" class="photo">
                    <div>
                        <div class="name">{{ item.name }}</div>
                        <div class="teacher">{{ item.teacherNames }}老师</div>
                    </div>
                </div>
                <div style="clear:both;"></div>
            </div>
            <el-dialog title="" :visible.sync="qxModal" width="20%">
                <h2 style="width: 100%;text-align: center;">是否删除收藏夹?</h2>
                <div class="btn2">
                    <div class="qx" @click="qxModal = false">取消</div>
                    <el-button class="qd" type="primary" @click="deleteFolder()">
                        确定
                    </el-button>
                </div>
            </el-dialog>
        </div>
        <homeFooter />
    </div>
</template>

<script>
import homeHeader from '@/components/homeHeader.vue'
import homeFooter from '@/components/homeFooter.vue'
import { queryCollectSubjectList, deleteFolder } from "@/api/home";
export default {
    components: {
        homeHeader, homeFooter
    },
    data() {
        return {
            id: '',
            name: '',
            lessonList: [],
            qxModal: false
        }
    },
    mounted() {
        this.id = this.$route.query.id;
        this.queryCollectSubjectList()
    },
    beforeDestroy() {
    },
    methods: {
        toDetail(id) {
            this.$router.push({
                path: '/lessonDetail',
                query: { id }
            })
        },
        async deleteFolder() {
            try {
                let res = await deleteFolder({ id: this.id });
                if (res.code == 200) {
                    this.$message.success('删除成功');
                    setTimeout(() => {
                        this.$router.push({
                            path: '/myCollect'
                        })
                    }, 100);
                }
            } finally {
                this.qxModal = false;
            }
        },
        async queryCollectSubjectList() {
            let res = await queryCollectSubjectList({});
            if (res.code == 200) {
                res.data.forEach((d) => {
                    if (d.id == this.id) {
                        this.name = d.folderName;
                        this.lessonList = d.subjectList
                    }
                })
            }
        }
    }
}
</script>
<style scoped lang="less">
header {
    width: 100%;
    height: .92rem;
}

.home {
    background: #f5f6f8;
    width: 100%;
}

.btn2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: .3rem;

    .qx {
        width: 1.43rem;
        height: .48rem;
        border: .01rem solid #DDDDDD;
        border-radius: .24rem;
        font-size: .18rem;
        font-family: 'PingFang SC';
        font-weight: 400;
        color: #36393D;
        text-align: center;
        line-height: .48rem;
        margin: 0 .15rem;
        cursor: pointer;
        transition: filter 0.3s;

        &:hover {
            filter: brightness(1.01);
        }
    }

    .qd {
        width: 1.43rem;
        height: .48rem;
        background: linear-gradient(90deg, #4C88EB, #3D70E7);
        border-radius: .24rem;
        font-size: .18rem;
        font-family: 'PingFang SC';
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        margin: 0 .15rem;
        cursor: pointer;
        transition: filter 0.3s;

        &:hover {
            filter: brightness(1.1);
        }
    }
}

.content {
    width: 14.78rem;
    margin: auto;
    padding-bottom: .5rem;

    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: .39rem 0;
        font-size: .18rem;
        font-family: 'PingFang SC';
        font-weight: 400;
        color: #979EA7;

        >div {
            display: flex;
            align-items: center;

            img {
                width: .4rem;
                height: .4rem;
                margin-right: .07rem;
            }

            >span {
                font-size: .3rem;
                font-family: 'pangmen';
                font-weight: 400;
                color: #2D2D2D;
            }
        }

        .del {
            width: .2rem;
            height: .2rem;
            margin-right: .04rem;
        }
    }

    .list {

        .list_item {
            width: 3.47rem;
            height: 3.1rem;
            background: #FFFFFF;
            box-shadow: 0 0 .2rem 0 rgba(0, 0, 0, 0.3);
            border-radius: .1rem;
            display: inline-block;
            float: left;
            margin-right: .3rem;
            margin-bottom: .3rem;
            overflow: hidden;
            position: relative;
            cursor: pointer;
            transition: transform .3s;

            &:hover {
                transform: scale(1.03);
            }

            &:nth-child(4n) {
                margin-right: 0;
            }

            .photo {
                width: 100%;
                height: 1.96rem;
                background: #eee;
            }

            >div {
                padding: .19rem .2rem;
                box-sizing: border-box;
                width: 100%;

                .name {
                    font-size: .2rem;
                    font-family: 'PingFang SC';
                    font-weight: 700;
                    color: #36393D;
                }

                .teacher {
                    font-size: .16rem;
                    font-family: 'PingFang SC';
                    font-weight: 400;
                    color: #6D737A;
                    margin: .13rem 0 .13rem;
                }

                .text {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .left {
                        font-size: .16rem;
                        font-family: 'PingFang SC';
                        font-weight: 400;
                        color: #4C88EB;
                    }

                    .right {
                        font-size: .16rem;
                        font-family: OPPOSans;
                        font-weight: 800;
                        color: #FF7800;
                    }
                }

                .bottom {
                    position: absolute;
                    left: .2rem;
                    bottom: .2rem;
                    display: flex;
                    align-items: center;

                    img {
                        width: .162rem;
                        height: .162rem;
                        margin-right: .07rem;
                    }

                    font-size: .16rem;
                    font-family: 'PingFang SC';
                    font-weight: 400;
                    color: #979EA7;
                }
            }
        }
    }
}
</style>

