<template>
    <div style="width: 100%;" class="workDetail">
        <header>
            <homeHeader :active="2" />
        </header>
        <div class="content">
            <div class="back" @click="back()">
                <img src="../static/img/icon_back2.png">返回
            </div>
            <div class="section">
                <div class="section_title">
                    <div class="section1" v-if="taskInfo.status == 0 && taskInfo.isCorrect == 0 && statu == 0">
                        <div class="section1_">
                            未开始
                        </div>
                    </div>
                    <div class="section1" v-if="statu == 1 && taskInfo.isCorrect == 0 && taskInfo.status != 1">
                        <div class="section1_">
                            进行中
                        </div>
                    </div>
                    <div class="section1"
                        v-if="(taskInfo.status == 1 && taskInfo.type != 1) || taskInfo.status == 1 && taskInfo.type == 1 && taskInfo.isCorrect == 0">
                        <div class="section1_">
                            已完成
                        </div>
                    </div>
                    <div class="section1" v-if="(taskInfo.type == 1 || taskInfo.type == 4) && taskInfo.isCorrect == 1">
                        <div class="section1_">
                            需订正
                        </div>
                    </div>
                    <div class="section2" v-if="taskInfo.status == 0">
                        {{ taskInfo.taskName ? taskInfo.taskName : '' }}
                    </div>
                    <div class="section2"
                        v-if="(taskInfo.status == 1 && taskInfo.type != 1) || (status == 1 && taskInfo.type == 1 && taskInfo.isCorrect == 0)">
                        作业已完成
                    </div>
                    <div class="section3" v-if="taskInfo.type != 1 && taskInfo.status == 0">
                        截止日期：{{ taskInfo.endTime ? taskInfo.endTime : '' }}
                    </div>
                    <div class="section3" v-if="taskInfo.questionList && taskInfo.questionList.length > 0">
                        <img src="../static/img/icon_tm.png"> 共{{ taskInfo.questionList.length }}道题
                    </div>
                    <div class="section3" v-if="(taskInfo.type == 1 || taskInfo.type == 4) && taskInfo.isCorrect == 1">
                        {{ correctionList.length }}道错题需订正
                    </div>
                </div>
            </div>
            <div class="btn" @click="beginWork()"
                v-if="taskInfo.type == 1 && taskInfo.isCorrect == 0 && taskInfo.status == 0">
                <span>开始做作业</span>
            </div>
            <div class="btn" @click="correction()" v-if="taskInfo.type == 1 && taskInfo.isCorrect == 1">
                <span>订正</span>
            </div>
            <div class="btn" @click="back()"
                v-if="(taskInfo.status == 1 && taskInfo.type != 1) || taskInfo.status == 1 && taskInfo.type == 1 && taskInfo.isCorrect == 0">
                <span>返回</span>
            </div>
        </div>
        <homeFooter style="margin-top: .8rem;" />
    </div>
</template>

<script>
import homeHeader from '@/components/homeHeader.vue'
import homeFooter from '@/components/homeFooter.vue'
import { queryStudentTaskInfo, dailyTaskCorrect } from "@/api/home";
export default {
    components: {
        homeHeader, homeFooter
    },
    data() {
        return {
            taskInfo: [],
            correctionList: [],
            begin: false,
            statu: 0
        }
    },
    mounted() {
        this.id = this.$route.query.id
        this.queryStudentTaskInfo(this.id)
    },
    beforeDestroy() {
    },
    methods: {
        back() {
            window.history.back(-1);
            setTimeout(() => {
                if (this.$route.path == '/workDetail') {
                    this.back()
                }
            }, 100);
        },
        beginWork() {
            this.$router.push({
                path: '/beginDailyWork',
                query: {
                    id: this.id,
                    correction: 0
                }
            })
        },
        async correction() {
            let res = await dailyTaskCorrect({
                id: this.id
            })
            if (res.code == 200) {
                this.$router.push({
                    path: '/beginDailyWork',
                    query: {
                        id: this.id,
                        correction: 1
                    }
                })
            }
        },
        async queryStudentTaskInfo(id) {
            let res = await queryStudentTaskInfo({
                id
            })
            if (res.code == 200) {
                if (res.data.type == 1) {
                    let correctionList = [];
                    let status = 0;
                    res.data.questionList.forEach((d, i) => {
                        if (d.isRight == 2) {
                            correctionList.push({
                                ...d,
                                index: i + 1
                            })
                        }
                        if (d.status == 1) {
                            status = 1
                        }
                    })
                    if (status == 1) {
                        this.statu = 1
                    }
                    this.correctionList = correctionList;
                }
                this.taskInfo = res.data;
                this.begin = false;
                this.status = res.data.status;
            }
        }
    }
}
</script>
<style scoped lang="less">
header {
    width: 100%;
    height: .92rem;
}

.workDetail {
    background: #f5f6f8;
    width: 100%;
    padding-top: .3rem;
    box-sizing: border-box;
    overflow: hidden;
}

.content {
    width: 14.8rem;
    height: 6.68rem;
    margin: auto;
    padding-bottom: .5rem;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 0px .2rem 0px rgba(0, 0, 0, 0.16);
    border-radius: .16rem;
    overflow: hidden;
    position: relative;

    .back {
        font-size: .18rem;
        font-family: 'PingFang SC';
        font-weight: 400;
        color: #979EA7;
        display: flex;
        align-items: center;
        margin: .21rem 0 0 .48rem;
        cursor: pointer;

        img {
            width: .2rem;
            height: .2rem;
            margin-right: .08rem;
        }
    }

    .section {
        padding-top: .4rem;

        .section_title {
            width: 100%;

            .section1,
            .section2,
            .section3,
            .section4 {
                width: 100%;
                text-align: center;
                font-weight: bold;
                color: #5990ec;
            }

            .section1 {
                width: 100%;
                display: flex;
                justify-content: center;
                white-space: nowrap;

                .section1_ {
                    width: 1.62rem;
                    height: 1.62rem;
                    background: rgba(76, 136, 235, 0.1);
                    border-radius: 50%;
                    font-size: .3rem;
                    font-family: 'PingFang SC';
                    font-weight: 500;
                    color: #4C88EB;
                    text-align: center;
                    line-height: 1.62rem;
                }
            }

            .section4 {
                width: 100%;
                display: flex;
                justify-content: center;
                margin-top: 30px;

                .section4_ {
                    font-size: 27px;
                    padding: 35px 130px;
                    background-color: #ffd684;
                    border-radius: 55px;
                    text-align: center;
                    font-weight: normal;
                    color: #000;
                    font-weight: bold;
                }


            }

            .section2 {
                font-size: .2rem;
                font-family: 'PingFang SC';
                font-weight: 500;
                color: #36393D;
                margin-top: .5rem;
            }

            .section3 {
                margin-top: .2rem;
                font-size: .16rem;
                font-family: 'PingFang SC';
                font-weight: 400;
                color: #6D737A;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: .2rem;
                    height: .2rem;
                    margin-right: .08rem;
                }
            }
        }
    }

    .btn {

        width: 4.4rem;
        height: .72rem;
        line-height: .72rem;
        background: linear-gradient(90deg, #4C88EB, #3D70E7);
        border-radius: .36rem;
        font-size: .2rem;
        text-align: center;
        color: #FFFFFF;
        margin: .5rem auto 0;
        cursor: pointer;
        transition: filter 0.3s;

        &:hover {
            filter: brightness(1.1);
        }
    }
}
</style>

